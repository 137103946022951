.password {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: 5px;
  background: #ddd;
  border-radius: 5px;
  width: 336px;
}
.password:before,
.password:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #ddd;
  border-style: solid;
  border-width: 0 1px 0;
  position: absolute;
  width: calc(25% - 1px);
  z-index: 10;
}
.password:before {
  left: calc(25%);
}
.password:after {
  right: calc(25%);
}

.strength-1::-webkit-progress-value {
  background-color: #e2066f;
  width: 100%;
}
.strength-2::-webkit-progress-value {
  background-color: #e2066f;
  width: 100%;
}

.strength-3::-webkit-progress-value {
  background-color: #e2066f;
  width: 100%;
}

.strength-4::-webkit-progress-value {
  background-color: #e2066f;
  width: 100%;
}

.strength-0 {
  color: #eb5e5e;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}
.strength-1 {
  color: #64de76;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}
.feedback {
  display: inherit !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
